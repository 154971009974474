import './App.scss';
import React from 'react';
import Settings from './Settings.js'

class App extends React.Component {

  modes = {
    RHYMING: 'rhyming',
    FIRSTLETTER: 'first-letter'
  }

  seed_words = ['cash', 'boat', 'raft', 'path', 'spark', 'make', 'wrap', 'crab', 'book', 'lark', 'fork',
      'fish', 'state', 'treat', 'bite', 'fruit', 'most', 'tank', 'cable', 'born', 'light', 'soon', 'mope', 'dome',
      'doom', 'trunk', 'star', 'pour', 'beer', 'bear', 'play', 'flew', 'flood', 'frost', 'stride', 'shave', 'roll',
      'flame', 'trick', 'flow', 'joke', 'land', 'humble', 'shamble', 'bonk', 'clock'];

  seed_letters = {"a":8.2,"b":1.5,"c":2.8,"d":4.3,"e":13,"f":2.2,"g":2,"h":6.1,"i":7,"j":0.15,"k":0.77,"l":4,"m":2.4,"n":6.7,"o":7.5,"p":1.9,"q":0.095,"r":6,"s":6.3,"t":9.1,"u":2.8,"v":0.98,"w":2.4,"x":0.15,"y":2,"z":0.074};

  constructor() {
    super()

    this.state = this.getBlankStateForMode(this.modes.RHYMING);
    this.state.background = {};
    this.state.duplication_error = false;
    this.state.settings_modal_display = false;
    this.state.settings = {
      'allow_submit_rhyme_error': false,
      'highlight_rhyme_error': true,
    }
  }

  componentDidMount() {
    this.loadRhymes();
    this.loadBackground();
  }

  getBlankStateForMode(mode) {
    let seed = null;
    switch(mode) {
      case this.modes.RHYMING:
        seed = this.getNewSeedWord();
        return {
          mode: this.modes.RHYMING,
          current_word: '',
          seed: seed,
          words: [seed],
          error: false,
          rhymes: [seed],
        }
      case this.modes.FIRSTLETTER:
        seed = this.getNewSeedLetter();
        return {
          mode: this.modes.FIRSTLETTER,
          current_word: '',
          seed: seed,
          words: [seed],
          error: false,
          rhymes: []
        }
      default:
        return {}
    }
  }

  getNewSeedWord() {
    return this.seed_words[Math.floor(Math.random() * this.seed_words.length)];
  }

  getNewSeedLetter() {
    return Object.keys(this.seed_letters)[Math.floor(Math.random() * Object.keys(this.seed_letters).length)];
  }

  loadRhymes() {
    console.log("Loading rhymes for " + this.state.seed);
    fetch('https://api.datamuse.com/words?max=0&rel_rhy=' + this.state.seed)
      .then((data) => {
        if (data.ok) {
          return data.json()
        }
        throw new Error('ERRORRRRRR!!!!!!!!')
      })
      .then((data) => {
        this.setState((prevState) => {
          return {
            rhymes: prevState.rhymes.concat(data.map(word => word.word)),
          }
        })
      })
  }

  loadBackground() {
    fetch('backgrounds.json')
      .then((data) => {
        if (data.ok) {
          return data.json()
        }
        throw new Error('ERRORRRRRR!!!!!!!!')
      })
      .then((data) => {
        this.setState((prevState) => {
          return {
            background: data[Math.floor(Math.random() * data.length)],
          }
        })
      })
  }

  toggleSettingsModal(force = null) {
    this.setState((prevState) => {
      return {
        settings_modal_display: force == null ? !prevState.settings_modal_display : force,
      }
    })
  }

  deleteWord(event) {
    const target = event.target;
    const value = target.innerHTML;

    this.setState((prevState) => {
      let _words = [...prevState.words]
      _words.splice(_words.indexOf(value), 1)
      return {
        words: _words,
      }
    })
  }

  changeMode(mode) {
    if (Object.values(this.modes).includes(mode)) {
      console.log(mode)
      this.setState(
        this.getBlankStateForMode(mode),
        () => {
          if (mode === this.modes.RHYMING) {
            this.loadRhymes();
          }
        }
      )
    }
  }

  onChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((prevState) => {
      let newState = {
        [name]: value,
      }

      let duplication_error = false;
      let rhyme_error = false;
      let letter_error = false;

      if (prevState.words.includes(value)) {
        duplication_error = true;
      }

      if ( this.state.mode === this.modes.RHYMING && !prevState.rhymes.includes(value) && !prevState.settings.allow_submit_rhyme_error) {
        rhyme_error = true;
      }

      if (this.state.mode === this.modes.FIRSTLETTER && value.charAt(0) !== this.state.seed) {
        letter_error = true;
      }

      if (value !== '') {
        newState.error = (duplication_error || rhyme_error || letter_error);
      } else {
        newState.error = false;
      }

      return newState;
    });
  }

  onChangeSetting(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((prevState) => {
      let settings = prevState.settings;
      settings[name] = value;
      return {
        settings: settings,
        }
      }
    )
  }

  onSubmit(event) {
    event.preventDefault()
    this.setState((prevState) => {
      if (this.state.current_word === "" || this.state.duplication_error || this.state.error) {
        return null;
      }

      return {
        words: [this.state.current_word].concat(prevState.words),
        current_word: '',
      }
    })
  }

  onKeyDown(event, mode) {
    if (event.key === 'Enter') {
      this.changeMode(mode)
    }
  }

  render() {
    return (
      <div className="app">
        <div className="main panel">
          <span className="settings-toggle clickable" tabIndex="1" onClick={ this.toggleSettingsModal.bind(this) }></span>
          <ul className="mode-buttons row">
            <li
              id="rhyming"
              tabIndex="1"
              className={ (this.state.mode === this.modes.RHYMING ? 'active' : '') + ' panel-button' }
              onClick={ () => { this.changeMode(this.modes.RHYMING) } }
              onKeyDown={ (event) => { this.onKeyDown(event, this.modes.RHYMING) } }
            >Rhyming</li>
            <li
              id="first-letter"
              tabIndex="1"
              className={ (this.state.mode === this.modes.FIRSTLETTER ? 'active' : '') + ' panel-button' }
              onClick={ () => { this.changeMode(this.modes.FIRSTLETTER) } }
              onKeyDown={ (event) => { this.onKeyDown(event, this.modes.FIRSTLETTER) } }
            >First Letter</li>
          </ul>
          <form className={ (this.state.error || this.state.duplication_error ? 'error' : '') } onSubmit={ this.onSubmit.bind(this) }>
            <input name="current_word" type="text" placeholder="Type here..." aria-label="Type a word here..." value={ this.state.current_word } onChange={ this.onChange.bind(this) } autoComplete="off"/>
            <ul className="words">
              { this.state.words.map(( word ) => {
                let _className = 'clickable ';
                if (this.state.mode === this.modes.RHYMING) {
                  _className += !this.state.rhymes.includes(word) && this.state.settings.highlight_rhyme_error ? 'error' : '';
                }
                return <li className={ _className } onClick={ this.deleteWord.bind(this) }>{ word }</li>
              })}
            </ul>
          </form>
        </div>
        <div className="footer">
          <span>Andy Zito  -  <a href="https://andyzito.github.io">andyzito.github.io</a></span>
          <span><a href={ this.state.background.source }>Current background image source</a></span>
          <span>Thanks to <a href="https://www.datamuse.com/api">https://www.datamuse.com/api</a> for rhyming data</span>
        </div>
        <Settings
          data={ this.state.settings }
          display={ this.state.settings_modal_display }
          onChange={ this.onChangeSetting.bind(this) }
          toggleSelfDisplay={ this.toggleSettingsModal.bind(this) }
        />
        <div className="background" style={ {'backgroundImage': 'url(' + this.state.background.embed + ')'} }></div>
      </div>
    );
  }
}

export default App;

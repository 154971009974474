import './Settings.scss';
import React from 'react';

export default class Settings extends React.Component {
    onSubmit(event) {
        event.preventDefault();
    }

    hide(event) {
        this.props.hide();
    }

    render() {
        return(
            <div className="settings-container" style={ {'display': this.props.display ? 'flex' : 'none' } }>
                <div className="settings-modal panel">
                    <h1>Settings</h1>
                    <form id="settings" onSubmit={ this.onSubmit.bind(this) }>
                    <span className="checkbox">
                        <label htmlFor={ this.props.name }>
                            <input
                                type="checkbox"
                                checked={ this.props.data.allow_submit_rhyme_error }
                                name="allow_submit_rhyme_error"
                                onChange={ this.props.onChange }
                            />
                            Allow submission of rhyme errors
                        </label>
                    </span>
                    <span className="checkbox">
                        <label htmlFor={ this.props.name }>
                            <input
                                type="checkbox"
                                checked={ this.props.data.highlight_rhyme_error }
                                name="highlight_rhyme_error"
                                onChange={ this.props.onChange }
                            />
                            Highlight rhyme errors
                        </label>
                    </span>
                    </form>
                </div>
                <div className="settings-overlay" onClick={ () => { this.props.toggleSelfDisplay(false) } }></div>
            </div>
        )
    }
}